// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alipay-js": () => import("./../src/pages/alipay.js" /* webpackChunkName: "component---src-pages-alipay-js" */),
  "component---src-pages-dof-js": () => import("./../src/pages/dof.js" /* webpackChunkName: "component---src-pages-dof-js" */),
  "component---src-pages-edge-shelter-js": () => import("./../src/pages/edge-shelter.js" /* webpackChunkName: "component---src-pages-edge-shelter-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inforest-js": () => import("./../src/pages/inforest.js" /* webpackChunkName: "component---src-pages-inforest-js" */),
  "component---src-pages-si-hunt-js": () => import("./../src/pages/si-hunt.js" /* webpackChunkName: "component---src-pages-si-hunt-js" */),
  "component---src-pages-sketch-clip-js": () => import("./../src/pages/sketch-clip.js" /* webpackChunkName: "component---src-pages-sketch-clip-js" */),
  "component---src-pages-smartpill-js": () => import("./../src/pages/smartpill.js" /* webpackChunkName: "component---src-pages-smartpill-js" */),
  "component---src-pages-tide-mobile-js": () => import("./../src/pages/tide-mobile.js" /* webpackChunkName: "component---src-pages-tide-mobile-js" */),
  "component---src-pages-works-js": () => import("./../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

